import { getImage, ImageQuery } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  OwnUpHeader
} from '@rategravity/own-up-component-library';
import React from 'react';
import { founderData } from '../../../data/content/about';
import { metadata } from '../../../data/metadata/images';
import { colors } from '../../../modules/colors';
import { SectionLabel } from '../../section-label';
import { Catchphrase, ResponsivePrimaryText } from '../../typography';

type FounderData = typeof founderData;

const FounderWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  width: 400,
  padding: '0px 50px 50px',
  variants: {
    mediumAndDown: {
      width: '80%',
      padding: '0px 0px 50px'
    }
  }
});

const FounderWrapper = createOwnUpComponent('section', FounderWrapperStyle);

const ImageWrapperStyle = createOwnUpStyle({
  width: '100%',
  margin: 'auto',
  textAlign: 'center'
});

const ImageWrapper = createOwnUpComponent(OwnUpBox, ImageWrapperStyle);

const NameStyle = createOwnUpStyle({
  fontSize: 30,
  marginTop: 22,
  textAlign: 'center'
});

const Name = createOwnUpComponent(OwnUpHeader, NameStyle);

const TitleStyle = createOwnUpStyle({
  fontSize: 17,
  paddingBottom: 15,
  textAlign: 'center'
});

const Title = createOwnUpComponent(OwnUpHeader, TitleStyle);

const TextWrapperStyle = createOwnUpStyle({
  textAlign: 'center',
  paddingBottom: 22
});

const TextWrapper = createOwnUpComponent(OwnUpBox, TextWrapperStyle);

/**
 * Individual founder
 */
export const Founder = ({
  Image,
  data: { name, title, catchphrase, bio }
}: {
  Image: JSX.Element | null;
  data: FounderData[0];
}) => (
  <FounderWrapper aria-label={`${name} ${title}`}>
    <ImageWrapper>{Image}</ImageWrapper>
    <Name level={3}>{name}</Name>
    <Title variant="greeting">{title}</Title>
    <TextWrapper>
      <Catchphrase
        align="center"
        style={{
          backgroundColor: colors.MINT
        }}
      >
        {catchphrase}
      </Catchphrase>{' '}
    </TextWrapper>
    <TextWrapper>
      <ResponsivePrimaryText>{bio}</ResponsivePrimaryText>
    </TextWrapper>
  </FounderWrapper>
);

const FoundersWrapperStyle = createOwnUpStyle({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  '& img': {
    borderRadius: '50%'
  },
  variants: {
    mediumAndDown: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
});

const FoundersWrapper = createOwnUpComponent(OwnUpBox, FoundersWrapperStyle);

const Section = createOwnUpComponent('section', {});

export const imgStyle = {
  maxWidth: 250,
  margin: 'auto',
  position: 'relative' as const,
  borderRadius: '50%'
};

/**
 * Group of founders
 */
export const Founders = ({
  imageData,
  founderData
}: {
  imageData: ImageQuery;
  founderData: FounderData;
}) => (
  <Section aria-label="founders">
    <SectionLabel>Our Founders</SectionLabel>
    <FoundersWrapper>
      {founderData.map((data, i) => {
        const Image = getImage(imageData, data.image, metadata, imgStyle);
        return <Founder Image={Image} data={data} key={`founder-${i}`} />;
      })}
    </FoundersWrapper>
  </Section>
);
